<template>
  <div id="app">
    <nav-bar />
    <b-container>
      <router-view :key="$route.path" />
    </b-container>
    <Chatbot />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Chatbot from '@/components/Chatbot.vue'

export default {
  name: 'MainApp',
  components: { NavBar, Chatbot },
  mounted () {
    this.$root.$on('editPicture', this.$bvModal.show('#edit-dpic-modal'))
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/vendors/bootstrap-vue/index";

:root {
  --color: #3c3163;
  --transition-time: 0.5s;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
}
</style>

<template>
  <div class="home">
    <b-row class="hero">
      <div class="cta text-left">
        <h1>Blog Your Mind Out</h1>
        <h3>Do articles on any topic, the easy way</h3>
        <div class="buttons">
          <b-button class="buttons__blog"  variant="outline-light" @click="$router.push('/blog')">Start Blogging</b-button>
          <b-button class="buttons__search" variant="outline-success" @click="$router.push('/search')">Search for Articles</b-button>
        </div>
      </div>
      <div class="action"></div>
    </b-row>
    <b-row class="articles">
      <h1>Featured Articles</h1>
      <section class="cards-wrapper">
        <thumbnail v-for="article in recentArticles" :key="article.id" :article="article" />
      </section>
    </b-row>
    <b-row class="user-invitation">
      <h1>Wanna Join Us?</h1>
      <p>Starting to blog is as easy as <a href="#" @click="signup()">creating your writer account</a> and you can start blogging away! No fees or charges involved, we promise.</p>
      <p>Already have an account? <a href="#" @click="login()">Login here</a> to continue!</p>
    </b-row>
    <b-row class="testimonials">
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Thumbnail from '@/components/Thumbnail.vue'

export default {
  name: 'HomePage',
  components: { Thumbnail },
  computed: {
    ...mapState({
      recentArticles: state => state.articles.posts
    })
  },
  methods: {
    loadRecentArticles () {
      try {
        this.$store.dispatch('articles/load')
      } catch (error) {
        console.log(error)
      }
    },
    login () {
      this.$store.dispatch('requestLogin')
      this.$router.push({ name: 'UserFormPage' })
    },
    signup () {
      this.$store.dispatch('requestSignup')
      this.$router.push({ name: 'UserFormPage' })
    }
  },
  beforeMount () {
    this.loadRecentArticles()
  }
}
</script>

<style scoped>
.home > .row {
  margin-block: 1em;
}
.home > .row:not(.hero) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: larger;
  padding:.7em 1.4em;
}
.home > .row:not(.hero) > h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.hero {
  background: linear-gradient(to bottom right, rgba(7, 231, 194, 0.76), rgba(7, 231, 164, 0.76) 25%, rgba(7, 240, 65, 0.76) 65%, rgba(182, 231, 7, 0.76) 100%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(36rem, 1fr));
  gap: .5em;
}
.cta  {
  min-height: 45vh;
  padding: 3rem 2rem;
  color: rgb(3, 55, 100);
}
.cta h1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
  font-size: 3em;
  padding-top: 2rem;
}
.cta h3 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  padding-bottom: 1rem;
}
.buttons {
  margin: 1rem auto;
  padding: .72rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border-radius: .5rem;
}
.buttons > button {
  margin: 1em;
  box-shadow: 0 0 57px rgba(31, 30, 30, 0.247);
}
.action {
  width: min(35rem, 100%);
  height: auto;
  background: rgba(100, 55, 3, 0.041);
  box-shadow: 2px 3px 12px rgba(31, 30, 30, 0.247);
}
.user-invitation {
  background-color: rgb(0, 0, 0);
  color: whitesmoke;
}
/* .user-invitation a {
  text-decoration: wavy;
  text-decoration-color: bisque;
} */
</style>

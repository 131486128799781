import Vue from 'vue'
import Vuex from 'vuex'

import { user } from './user.module'
import { articles } from './articles.module'
import { data } from './data.module'
import { auth } from './auth.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentUser: {},
    chatHistory: [{ party: 'bot', message: 'Welcome. What do you need to know?' }],
    loginRequested: true,
    signupRequested: false,
    isArticleLoading: false
  },
  getters: {
    IS_ARTICLE_LOADING: (state) => {
      return state.isArticleLoading
    },
    LOGIN_REQUESTED: (state) => {
      return state.loginRequested
    },
    SIGNUP_REQUESTED: (state) => {
      return state.signupRequested
    },
    USER: (state) => {
      return state.currentUser
    },
    CHAT_HISTORY: (state) => {
      return state.chatHistory
    }
  },
  mutations: {
    requestLogin: (state) => {
      state.loginRequested = true
      state.signupRequested = false
    },
    requestSignup: (state) => {
      state.loginRequested = false
      state.signupRequested = true
    },
    setArticleLoading: (state, action) => {
      state.isArticleLoading = action
    },
    setUser: (state, data) => {
      state.currentUser = data
    },
    extendChat: (state, contribution) => {
      state.chatHistory.push(contribution)
    },
    resetChat: (state) => {
      state.chatHistory = [{ party: 'bot', message: 'Welcome. What do you need to know?' }]
    },
    resetUser: (state) => {
      state.currentUser = {}
    }
  },
  actions: {
    requestLogin: (context) => {
      context.commit('requestLogin')
    },
    requestSignup: (context) => {
      context.commit('requestSignup')
    },
    setArticleLoading: (state, context) => {
      state.isArticleLoading === true ? context.commit('setArticleLoading', false) : context.commit('setArticleLoading', true)
    },
    resetUser: (context) => {
      let cUser
      if (localStorage.getItem('user') && localStorage.getItem('user') !== '') {
        cUser = JSON.parse(localStorage.getItem('user'))
      } else {
        cUser = { firstName: '', lastName: '', status: 'loggedOut' }
      }
      context.commit('setUser', cUser)
    },
    extendChat: (context, data) => {
      context.commit('extendChat', data)
    },
    resetChat: (context) => {
      context.commit('resetChat')
    }
    // resetUser: (context) => {
    //   context.commit('resetUser')
    // }
  },
  modules: {
    articles,
    user,
    data,
    auth
  }
})

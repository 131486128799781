import { articleService } from '@/services/article.service'

const state = {
  article: {},
  posts: [],
  dynamicPosts: [],
  status: {}
}

const actions = {
  open ({ commit }, id) {
    commit('readRequest')
    articleService.getSingle(id).then(article => article.isError ? commit('readFailure', article) : commit('readSuccess', article))
  },
  load ({ commit }) {
    commit('loadRequest')
    articleService.getAll().then(result => result.isError ? commit('loadFailure', result) : commit('loadSuccess', articleService.prepareArticles(result.articles)))
  },
  searchTag ({ commit }, tag) {
    commit('searchRequest')
    articleService.getByTag(tag).then(load => load.isError ? commit('searchFailure', load) : commit('searchSuccess', articleService.prepareArticles(load.articles)))
  },
  searchWriter ({ commit }, writer) {
    commit('searchRequest')
    articleService.getByWriterName(writer).then(load => load.isError ? commit('searchFailure', load) : commit('searchSuccess', articleService.prepareArticles(load.articles)))
  },
  async searchSimilar ({ commit }, id) {
    commit('searchRequest')
    const similar = []
    await articleService.getSingle(id).then(current => {
      if (current.isError) {
        commit('searchFailure', current)
      } else {
        for (let i = 0; i < current.article.tags.length; i++) {
          articleService.getByTag(current.article.tags[i]).then(results => {
            if (results.isError) {
              commit('searchFailure', results)
            } else {
              for (const j in results.articles.length) {
                similar.push(results.articles[j])
              }
            }
          })
        }
        similar.filter(post => post.id !== id)
      }
    })
    if (similar.length > 0) { commit('searchSuccess', articleService.prepareArticles(similar)) }
  },
  getBlogs ({ commit }, writerId) {
    commit('searchRequest')
    articleService.getByWriterId(writerId).then(writerBlogs => writerBlogs.isError ? commit('searchFailure', writerBlogs) : commit('searchSuccess', articleService.prepareArticles(writerBlogs.articles)))
  },
  create ({ commit }, article) {
    commit('postRequest')
    articleService.addNew(article).then(result => result.isError ? commit('postFailure', result) : commit('postSuccess', result))
  },
  edit ({ commit }, article) {
    commit('editRequest')
    articleService.edit(article).then(result => result.isError ? commit('editFailure', result) : commit('editSuccess', result))
  },
  delete ({ commit }, id) {
    commit('deleteRequest')
    articleService.delete(id).then(result => result.isError ? commit('deleteFailure', result) : commit('deleteSuccess', result))
  }
}

const mutations = {
  readRequest (state) {
    state.status = { opening: true }
    state.article = null
  },
  readSuccess (state, result) {
    state.article = result.article
    state.status = { opened: true }
  },
  readFailure (state, error) {
    state.article = null
    state.status = { opened: false, readError: error.message }
  },
  loadRequest (state) {
    state.status = { loading: true }
    state.posts = state.posts.splice(0, state.posts.length)
  },
  loadSuccess (state, posts) {
    state.posts = posts
    state.status = { loaded: true }
  },
  loadFailure (state, error) {
    state.posts = []
    state.status = { loaded: false, loadError: error.message }
  },
  searchRequest (state) {
    state.dynamicPosts = state.dynamicPosts.splice(0, state.dynamicPosts.length)
    state.status = { searching: true }
  },
  searchSuccess (state, posts) {
    state.dynamicPosts = posts
    state.status = { searched: true }
  },
  searchFailure (state, error) {
    state.status = { searched: false, searchError: error.message }
    state.dynamicPosts = []
  },
  postRequest (state) {
    state.status = { posting: true }
  },
  postSuccess (state, result) {
    state.status = { posted: true, message: result.message }
  },
  postFailure (state, error) {
    state.status = { posted: false, postError: error.message }
  },
  editRequest (state) {
    state.status = { editing: true }
  },
  editSuccess (state, result) {
    state.status = { edited: true, message: result.message }
  },
  editFailure (state, error) {
    state.status = { edited: false, editError: error.message }
  },
  deleteRequest (state) {
    state.status = { deleting: true }
  },
  deleteSuccess (state, result) {
    state.status = { deleted: true, message: result.message }
  },
  deleteFailure (state, error) {
    state.status = { deleted: false, deleteError: error.message }
  }
}

export const articles = {
  namespaced: true,
  state,
  actions,
  mutations
}

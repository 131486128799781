// Authentication handler

import axios from 'axios'

const API_URL = '/api/User.php?action='

class AuthService {
  async loginUser (user) {
    const formData = new FormData()
    formData.append('email', user.email)
    formData.append('password', user.password)
    formData.append('remember', user.remember)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'signin',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      localStorage.setItem('user', JSON.stringify(response.data.user))
      return { isError: false, user: response.data.user }
    }
  }

  async logout () {
    const response = await axios.get(API_URL + 'signout')
    if (!response.data.error) {
      localStorage.removeItem('user')
    }
    return { isError: response.data.error, message: response.data.message }
  }

  async registerUser (user) {
    const formData = new FormData()
    formData.append('first_name', user.firstName)
    formData.append('last_name', user.lastName)
    formData.append('email', user.email)
    formData.append('phone', user.phone)
    formData.append('role', 'blogger')
    formData.append('password', user.password)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'signup',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    return { isError: response.data.error, message: response.data.message }
  }

  async verifyUser (token) {
    const response = await await axios.get(`./api/Verify.php?token=${token}`)
    if (response.isError) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      localStorage.setItem('user', JSON.stringify(response.data.user))
      return { isError: response.data.error }
    }
  }
}

export default new AuthService()

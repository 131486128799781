import UserService from '@/services/user.service'

export const user = {
  namespaced: true,
  state: {
    message: { text: '', type: '' }
  },
  getters: {
    MESSAGE: function (state) {
      return state.message
    }
  },
  actions: {
    changePassword: async function ({ dispatch, commit }, payload) {
      const data = await UserService.updatePassword(payload)
      if (!data) {
        commit('NO_DATA')
      } else {
        data.isError ? commit('CHANGE_PASSWORD_FAIL', data) : dispatch('auth/logout', null, { root: true })
      }
    },
    updateUser: async function ({ dispatch, commit }, payload) {
      const data = await UserService.updateUser(payload)
      if (!data) {
        commit('NO_DATA')
      } else {
        data.isError ? commit('UPDATE_USER_FAIL', data) : dispatch('resetUser', null, { root: true })
      }
    },
    upgradeUser: async function ({ commit }, payload) {
      const data = await UserService.upgradeUser(payload.id, payload.role)
      if (!data) {
        commit('NO_DATA')
      } else {
        data.isError ? commit('UPDATE_USER_FAIL', data) : commit('UPDATE_USER_SUCCESS', data)
      }
    },
    downgradeUser: async function ({ commit }, payload) {
      const data = await UserService.downgradeUser(payload.id, payload.role)
      if (!data) {
        commit('NO_DATA')
      } else {
        data.isError ? commit('UPDATE_USER_FAIL', data) : commit('UPDATE_USER_SUCCESS', data)
      }
    },
    changePhoto: async function ({ dispatch, commit }, payload) {
      const data = await UserService.setPic(payload.image, payload.id)
      if (!data) {
        commit('NO_DATA')
      } else {
        data.isError ? commit('PHOTO_CHANGE_FAIL', data) : dispatch('resetUser', null, { root: true })
      }
    },
    deleteAccount: async function ({ dispatch, commit }, userID) {
      const data = await UserService.deleteUser(userID)
      if (!data) {
        commit('NO_DATA')
      } else {
        data.isError ? commit('DELETE_USER_FAIL', data) : dispatch('auth/logout', null, { root: true })
      }
    },
    clearMessage: async function ({ commit }) {
      await commit('CLEAR_MESSAGE')
    }
  },
  mutations: {
    UPDATE_USER_SUCCESS: function (state, data) {
      state.message.type = 'success'
      state.message.text = data.message
    },
    CHANGE_PASSWORD_FAIL: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    UPDATE_USER_FAIL: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    PHOTO_CHANGE_FAIL: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    DELETE_USER_FAIL: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    CLEAR_MESSAGE: function (state) {
      state.message.type = ''
      state.message.text = ''
    },
    NO_DATA: function (state) {
      state.message.type = 'danger'
      state.message.text = 'Bad response from server.'
    }
  }
}

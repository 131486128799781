import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomeView
  },
  {
    path: '/profile/:uid',
    name: 'ViewProfile',
    component: () => import('../views/ProfilePage.vue')
  },
  {
    path: '/article/:id',
    name: 'ArticlePage',
    component: () => import('../views/ArticlePage.vue')
  },
  {
    path: '/search',
    name: 'SearchPage',
    component: () => import('../views/SearchPage.vue')
  },
  {
    path: '/blog',
    name: 'ArticleFormPage',
    component: () => import('../views/ArticleFormPage.vue')
  },
  {
    path: '/blogger',
    name: 'WriterPage',
    component: () => import('../views/WriterPage.vue')
  },
  {
    path: '/editor',
    name: 'EditorPage',
    component: () => import('../views/EditorPage.vue')
  },
  {
    path: '/signin',
    name: 'UserFormPage',
    component: () => import('../views/UserFormPage.vue')
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: () => import('../views/AdminDashBoard.vue')
  },
  {
    path: '/portfolio/:name',
    name: 'WriterList',
    component: () => import('../views/WriterList.vue')
  },
  {
    path: '/verify/:token',
    name: 'VerifyUser',
    component: () => import('../views/VerifyPage.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   const publicPages = ['/userFormPage', '/searchPage', '/articlePage/:id', '/viewProfile/:uid', '/']
//   const authRequired = !publicPages.includes(to.path)
//   const loggedIn = localStorage.getItem('user')
//   // const user = JSON.parse(localStorage.getItem('user'))
//   const admin = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')).isAdmin : false

//   if (authRequired && !loggedIn) {
//     return next('/userFormPage')
//   } else if (!admin && to.path === '/adminDashBoard') {
//     return next(from.path)
//   }

//   next()
// })

export default router

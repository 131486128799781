// To do user-oriented activities

import axios from 'axios'

const API_URL = '/api/User.php?action='

class UserService {
  async updatePassword (user) {
    const formData = new FormData()
    formData.append('id', user.id)
    formData.append('oldPassword', user.oldPass)
    formData.append('newPassword', user.newPass)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'changePassword',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data) {
      return { isError: response.data.error, message: response.data.message }
    }
  }

  async upgradeUser (id, role) {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('role', role)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'upgrade',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data) {
      return { isError: response.data.error, message: response.data.message }
    }
  }

  async downgradeUser (id, role) {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('role', role)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'downgrade',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data) {
      return { isError: response.data.error, message: response.data.message }
    }
  }

  async updateUser (user) {
    const formData = new FormData()
    formData.append('id', user.id)
    formData.append('firstname', user.firstName)
    formData.append('lastname', user.lastName)
    formData.append('email', user.email)
    formData.append('phone', user.phone)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'update',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      await localStorage.setItem('user', JSON.stringify(response.data.user))
      return { isError: response.data.error, user: response.data.user }
    }
  }

  async setPic (image, id) {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('picture', image)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'updatePic',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      await localStorage.setItem('user', JSON.stringify(response.data.user))
      return { isError: response.data.error, user: response.data.user }
    }
  }

  async deleteUser (userID) {
    const formData = new FormData()
    formData.append('id', userID)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'delete',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data) {
      return { isError: response.data.error, message: response.data.message }
    }
  }
}

export default new UserService()

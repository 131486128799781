<!--The functionality-->
<script>
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'ChatBot',
  computed: {
    ...mapState({
      chatHistory: state => state.chatHistory
    }),
    loaderVisibility () {
      console.log(this.loading)
      return this.loading
    }
  },
  data () {
    return {
      question: '',
      loading: false
    }
  },
  methods: {
    toggleChat () {
      const chatbot = document.querySelector('.bot')
      chatbot.getAttribute('data-visible') === 'true' ? chatbot.setAttribute('data-visible', false) : chatbot.setAttribute('data-visible', true)
    },
    async sendQuestion () {
      this.$store.dispatch('extendChat', { party: 'user', message: this.question })
      this.loading = true
      const response = await axios({
        method: 'POST',
        url: 'https://api.datalawcompanion.org/Chat.php?action=question',
        data: { question: this.question },
        headers: { 'Content-Type': 'application/json' }
      })
      this.question = ''
      if (!response.data.error) {
        this.loading = false
        await this.$store.dispatch('extendChat', { party: 'bot', message: response.data.data.Answer })
      } else {
        this.loading = false
        this.$bvToast.toast(`Error accessing chatbot: ${response.data.message}`, { variant: 'danger' })
      }
    }
  }
}
</script>

<!--The HTML-->
<template>
    <div>
        <div class="bot" data-visible="false">
            <button class="close" @click="toggleChat"> X </button>
            <div class="bot__display">
                <div v-for="(entry, index) in chatHistory" :key="index">
                    <img class="icon" :src="entry.party === 'bot' ? require('@/assets/images/bot-icon-96.png') : require('@/assets/images/user-icon-80.png')" :alt="entry.party.split('')[0]" />
                    <p :class="`${entry.party}-text`">{{ entry.message }}</p>
                </div>
                <div class="loader" v-show="loading">
                    <img class="icon" :src="require('@/assets/images/bot-icon-96.png')" alt="B" />
                    <span class="bot-text">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </span>
                </div>
            </div>
            <div class="bot__input">
            <input type="text" placeholder="Your question" v-model="question" @keyup.enter="sendQuestion" />
            <button class="btn btn-small" @click="sendQuestion" :disabled="question === '' || loading === true">Ask</button>
            </div>
        </div>
        <div class="floater" @click="toggleChat"><img class="chat-icon" :src="require('@/assets/images/bot-icon-96.png')" alt="Chat" /></div>
    </div>
</template>

<!--The styling-->
<style>
.floater {
  width: 4.3rem;
  height: 4.3rem;
  padding: .3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  border: 1px inset rgb(250, 172, 55) ;
  border-radius: 50%;
  background-color: rgb(255, 235, 205);
  box-shadow: 2px 3px 9px rgba(1, 12, 12, 0.356);
}
.floater > img.chat-icon {
  width: 3rem;
  aspect-ratio: 1;
}
.bot {
  position: fixed;
  right: .5rem;
  height: 35rem;
  width: 25rem;
  z-index: 100;
  background-color: rgb(202, 201, 201);
  border: 3px groove rgb(173, 57, 3);
  border-radius: 12px;
  display: grid;
  grid-template-rows: min-content auto min-content;
  grid-template-columns: 1fr;
  transition: all ease-in-out 300ms;
}
.bot[data-visible='false'] {
  bottom: -100%;
}
.bot[data-visible='true'] {
  bottom: .3em;
}
button.close {
  text-align: end;
  padding-inline-end: .4em;
}
.bot__display{
  background-color: rgb(247, 244, 240);
  border: 1px inset;
  border-radius: 2px;
  margin-inline: .2em;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.bot__display > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: .2em;
}
.bot__display > div > img.icon {
  width: 2.5rem;
  aspect-ratio: 1;
  border-radius: 50%;
  margin-inline-end: .3rem;
}
.bot__display > div:has(p:not(.bot-text)) {
  direction: rtl;
}
.bot__display > div > p {
  font-size: smaller;
  text-align: start;
  color: whitesmoke;
  height: fit-content;
  margin: .2em;
  padding: .2em .5rem;
  border: none;
  border-radius: 6px;
}
.bot__display > div > p.bot-text {
  background-color: rgb(3, 65, 6);
}
.bot__display > div > p.user-text {
  background-color: rgb(89, 7, 122);
  direction: ltr;
}
div.loader {
  animation: opacity 100s cubic-bezier(0.445, 0.05, 0.55, 0.95), height 150s ease;
}
div.loader[data-visible='true'] {
  opacity: 1;
  height: auto;
}
div.loader[data-visible='false'] {
  opacity: 0;
  height: 0;
}
.loader > .bot-text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.loader > .bot-text > .dot {
  width: .5rem;
  height: .5rem;
  background: rgba(85, 83, 83, 0.733);
  margin-inline: .2rem;
  border-radius: 50%;
  animation: bounce 1s infinite cubic-bezier(0.39, 0.575, 0.565, 1), changeColor 1s infinite ease;
  animation-fill-mode: both;
}
.loader > .bot-text > .dot:nth-child(2) { animation-delay: .2s; }
.loader > .bot-text > .dot:nth-child(3) { animation-delay: .4s; }
.bot__input {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: .2em;
}
.bot__input > input {
  flex-grow: 1;
  margin: .1em;
}
.bot__input > button {
  background-color: rgb(2, 63, 63);
  color: antiquewhite;
  border-radius: 2px;
}
@keyframes bounce {
  0%, 20%, 80%, 100% { transform: translateY(0); }
  5%, 95% { transform: translateY(.5px); }
  35%, 65% { transform: translateY(-6px); }
  50% { transform: translateY(-9px); }
}
@keyframes bulge {
  0%, 80%, 100% {
    transform: scale(1.0);
  }
  40% {
    transform: scale(1.4);
  }
}
@keyframes changeColor {
  0%, 100% {
    background-color: rgba(100, 98, 98, 0.733);
  }
  50% {
    background-color: rgba(53, 51, 51, 0.733);
  }
}
</style>


import axios from 'axios'

export const articleService = {
  getAll,
  getByTag,
  getByWriterName,
  getByWriterId,
  getSingle,
  search,
  addNew,
  edit,
  addPic,
  prepareArticles,
  delete: _delete
}

async function getAll () {
  const response = await axios.get('./api/Article.php?action=read')
  return handleResponse(response)
}

async function getByTag (tag) {
  const formData = new FormData()
  formData.append('tag', tag)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=searchByTag',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

async function getByWriterName (name) {
  const formData = new FormData()
  formData.append('name', name)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=searchByName',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

async function getByWriterId (writerId) {
  const formData = new FormData()
  formData.append('id', writerId)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=searchByWriter',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

async function getSingle (id) {
  const formData = new FormData()
  formData.append('articleId', id)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=searchById',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

async function search (query) {
  let tagResults
  let writerResults
  let titleResults
  const res1 = await getByTag(query)
  res1.isError ? tagResults = [] : tagResults = res1
  const res2 = await getByWriterName(query)
  res2.isError ? writerResults = [] : writerResults = res2
  const formData = new FormData()
  formData.append('query', query)
  const res3 = await axios({
    method: 'POST',
    url: './api/Data.php?action=searchBlogs',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  handleResponse(res3).isError ? titleResults = [] : titleResults = handleResponse(res3)
  return { tagMatches: tagResults, writerMatches: writerResults, titleMatches: titleResults }
}

async function addNew (article) {
  const formData = new FormData()
  formData.append('title', article.title)
  formData.append('teaser', article.teaser)
  formData.append('thumbnail', article.thumbnail)
  formData.append('tags', article.tags)
  formData.append('content', article.content)
  formData.append('writer', article.userId)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=create',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

async function edit (article) {
  const formData = new FormData()
  formData.append('id', article.id)
  formData.append('title', article.title)
  formData.append('teaser', article.teaser)
  formData.append('thumbnail', article.thumbnail)
  formData.append('tags', article.tags)
  formData.append('content', article.content)
  formData.append('writer', article.userId)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=update',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

async function addPic (pic) {
  const formData = new FormData()
  formData.append('image', pic)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=addContentImage',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

async function _delete (id) {
  const formData = new FormData()
  formData.append('id', id)
  const response = await axios({
    method: 'POST',
    url: './api/Article.php?action=delete',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return handleResponse(response)
}

function prepareArticles (articles) {
  // remove duplicates, sort latest to earliest
  const flag = {}
  const unique = []
  if (articles) {
    articles.forEach(element => {
      if (!flag[element.id]) {
        flag[element.id] = true
        unique.push(element)
      }
    })
  }
  return unique.sort((a, b) => a.id > b.id ? -1 : 1)
}

function handleResponse (response) {
  if (response.status !== 200) {
    if (response.status === 401) {
      localStorage.removeItem('user')
      location.reload(true)
    } else {
      return { isError: true, message: response.statusText }
    }
  } else if (response.data.error) {
    return { isError: true, message: response.data.message }
  } else {
    return response.data
  }
}

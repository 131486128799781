import DataService from '@/services/data.service'
import { articleService } from '@/services/article.service'

export const data = {
  namespaced: true,
  state: {
    featuredBlogs: [],
    filteredBlogs: [],
    allBlogs: [],
    user: {},
    filteredUsers: [],
    allUsers: [],
    searchResults: { tagMatches: [], writerMatches: [], titleMatches: [] },
    searchFailed: false,
    message: { text: '', type: '' }
  },
  getters: {
    FEATURED_BLOGS: function (state) {
      return state.featuredBlogs
    },
    FILTERED_BLOGS: function (state) {
      return state.filteredBlogs
    },
    ALL_BLOGS: function (state) {
      return state.allBlogs
    },
    USER: function (state) {
      return state.user
    },
    FILTERED_USERS: function (state) {
      return state.filteredUsers
    },
    ALL_USERS: function (state) {
      return state.allUsers
    },
    SEARCH_RESULTS: function (state) {
      return state.searchResults
    },
    SEARCH_FAILED: function (state) {
      return state.searchFailed
    },
    MESSAGE: function (state) {
      return state.message
    }
  },
  actions: {
    getAllBlogs: async function ({ commit }) {
      const data = await DataService.getAllBlogs()
      data.isError ? commit('FETCH_PROPERTY_FAIL', data) : commit('FETCH_PROPERTY_SUCCESS', data)
    },
    getPartialBlogs: async function ({ commit }, payload) {
      const data = await DataService.getFilteredBlogs(payload)
      data.isError ? commit('FILTERED_PROPERTY_FAIL', data) : commit('FILTERED_PROPERTY_SUCCESS', data)
    },
    getFeaturedBlogs: async function ({ commit }, payload) {
      const data = await DataService.getFeaturedBlogs(payload)
      data.isError ? commit('FEATURED_PROPERTY_FAIL', data) : commit('FEATURED_PROPERTY_SUCCESS', data)
    },
    getNamedUsers: async function ({ commit }, name) {
      const data = await DataService.getNamedUsers(name)
      data.isError ? commit('NAMED_USERS_FAIL', data) : commit('NAMED_USERS_SUCCESS', data)
    },
    getAllUsers: async function ({ commit }) {
      const data = await DataService.getAllUsers()
      data.isError ? commit('FETCH_USERS_FAIL', data) : commit('FETCH_USERS_SUCCESS', data)
    },
    getSingleUser: async function ({ commit }, id) {
      const data = await DataService.getSingleUser(id)
      data.isError ? commit('SINGLE_USER_FAIL', data) : commit('SINGLE_USER_SUCCESS', data)
    },
    getPartialUsers: async function ({ commit }, payload) {
      const data = await DataService.getFilteredUsers(payload)
      data.isError ? commit('PARTIAL_USERS_FAIL', data) : commit('PARTIAL_USERS_SUCCESS', data)
    },
    search: async function ({ commit }, payload) {
      const data = await articleService.search(payload)
      data.titleMatches ? commit('SET_SEARCH_RESULTS', data) : commit('SEARCH_FAILURE')
    },
    clearMessage: async function ({ commit }) {
      await commit('CLEAR_MESSAGE')
    },
    clearData: function ({ commit }) {
      commit('DELETE_ALL_DATA')
    }
  },
  mutations: {
    FETCH_USERS_SUCCESS: function (state, data) {
      state.allUsers = data.users
    },
    PARTIAL_USERS_SUCCESS: function (state, data) {
      state.filteredUsers = data.users
    },
    NAMED_USERS_SUCCESS: function (state, data) {
      state.filteredUsers = data.users
    },
    SINGLE_USER_SUCCESS: function (state, data) {
      state.user = data.user
    },
    FETCH_BLOGS_SUCCESS: function (state, data) {
      state.allBlogs = data.blogs
    },
    FILTERED_BLOGS_SUCCESS: function (state, data) {
      state.filteredBlogs = data.blogs
    },
    FEATURED_BLOGS_SUCCESS: function (state, data) {
      state.featuredBlogs = data.blogs
    },
    SET_SEARCH_RESULTS: function (state, data) {
      state.searchResults = data
    },
    FETCH_USERS_FAIL: function (state, data) {
      state.allUsers = []
      state.message.type = 'danger'
      state.message.text = data.message
    },
    PARTIAL_USERS_FAIL: function (state, data) {
      state.filteredUsers = []
      state.message.type = 'danger'
      state.message.text = data.message
    },
    NAMED_USERS_FAIL: function (state, data) {
      state.filteredUsers = []
      state.message.type = 'danger'
      state.message.text = data.message
    },
    SINGLE_USER_FAIL: function (state, data) {
      state.user = {}
      state.message.type = 'danger'
      state.message.text = data.message
    },
    FETCH_BLOGS_FAIL: function (state, data) {
      state.allBlogs = []
      state.message.type = 'danger'
      state.message.text = data.message
    },
    FILTERED_BLOGS_FAIL: function (state, data) {
      state.filteredBlogs = []
      state.message.type = 'danger'
      state.message.text = data.message
    },
    FEATURED_BLOGS_FAIL: function (state, data) {
      state.featuredBlogs = []
      state.message.type = 'danger'
      state.message.text = data.message
    },
    SEARCH_FAILURE: function (state) {
      state.searchFailed = true
      state.message.type = 'danger'
      state.message.text = 'Error retrieving data'
    },
    CLEAR_MESSAGE: function (state) {
      state.message.type = ''
      state.message.text = ''
    },
    DELETE_ALL_DATA: function (state) {
      state.featuredBlogs = []
      state.filteredBlogs = []
      state.searchResults = []
      state.allBlogs = []
      state.allUsers = []
      state.filteredUsers = []
      state.user = {}
      state.message = { type: '', text: '' }
    }
  }
}

import AuthService from '@/services/auth.service'

export const auth = {
  namespaced: true,
  state: {
    message: { type: '', text: '' }
  },
  getters: {
    MESSAGE: function (state) {
      return state.message
    }
  },
  actions: {
    login: async function ({ commit }, user) {
      const data = await AuthService.loginUser(user)
      data.isError ? await commit('LOGIN_FAILURE', data) : await commit('LOGIN_SUCCESS')
    },
    logout: async function ({ commit }) {
      const data = await AuthService.logout()
      data.isError ? await commit('LOGOUT_FAILURE', data) : await commit('LOGOUT_SUCCESS', data)
    },
    register: async function ({ commit }, user) {
      const data = await AuthService.registerUser(user)
      data.isError ? await commit('REGISTER_FAILURE', data) : await commit('REGISTER_SUCCESS', data)
    },
    verify: async function ({ commit }, token) {
      const data = await AuthService.verifyUser(token)
      data.isError ? await commit('VERIFY_FAILURE', data) : await commit('VERIFY_SUCCESS')
    },
    clearMessage: async function ({ commit }) {
      await commit('CLEAR_MESSAGE')
    }
  },
  mutations: {
    LOGIN_SUCCESS: function (state, data) {
      state.message.type = 'success'
      state.message.text = 'Signed in'
    },
    LOGOUT_SUCCESS: function (state, data) {
      state.message.type = 'success'
      state.message.text = data.message
    },
    REGISTER_SUCCESS: function (state, data) {
      state.message.type = 'success'
      state.message.text = data.message
    },
    VERIFY_SUCCESS: function (state) {
      state.message.type = 'success'
      state.message.text = 'Successfully verified your email!'
    },
    LOGIN_FAILURE: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    LOGOUT_FAILURE: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    REGISTER_FAILURE: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    VERIFY_FAILURE: function (state, data) {
      state.message.type = 'danger'
      state.message.text = data.message
    },
    CLEAR_MESSAGE: function (state) {
      state.message.type = ''
      state.message.text = ''
    }
  }
}

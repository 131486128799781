// To serve data to the users

import axios from 'axios'

const API_URL = '/api/Data.php?action='

class DataService {
  async getAllUsers () {
    const response = await axios.get(API_URL + 'getUsers')
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, users: response.data.users }
    }
  }

  async getSingleUser (id) {
    const formData = new FormData()
    formData.append('id', id)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'getSingleUser',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, user: response.data.user }
    }
  }

  async getNamedUsers (name) {
    const formData = new FormData()
    formData.append('name', name)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'getNamedUsers',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, users: response.data.users }
    }
  }

  async getFilteredUsers (payload) {
    const formData = new FormData()
    formData.append('key', payload.filterBy)
    formData.append('value', payload.value)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'getFilteredUsers',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, users: response.data.users }
    }
  }

  async getAllBlogs () {
    const response = await axios.get(API_URL + 'getBlogs')
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, blogs: response.data.items }
    }
  }

  async getFeaturedBlogs () {
    const response = await axios.get(API_URL + 'getFeaturedBlogs')
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, blogs: response.data.items }
    }
  }

  async getFilteredBlogs (payload) {
    const formData = new FormData()
    formData.append('key', payload.filterBy)
    formData.append('value', payload.value)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'getFilteredBlogs',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, blogs: response.data.items }
    }
  }

  async searchBlogs (payload) {
    if (payload.searchQuery.length > 0) {
      let history = JSON.parse(localStorage.getItem('searchHistory'))
      history += payload.searchQuery.replace('@', ' ') + '@'
      localStorage.setItem('searchHistory', JSON.stringify(history))
    }
    const formData = new FormData()
    formData.append('query', payload.searchQuery)
    const response = await axios({
      method: 'POST',
      url: API_URL + 'searchBlogs',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    if (response.data.error) {
      return { isError: response.data.error, message: response.data.message }
    } else {
      return { isError: response.data.error, blogs: response.data.items }
    }
  }
}

export default new DataService()

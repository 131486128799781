<template>
  <div class="navbar-top">
    <b-navbar toggleable="lg" type="light">
        <b-navbar-brand :to="{ path: '/'}">
          <!-- brand logo goes here -->We-Blog
        </b-navbar-brand>
        <!-- collapsible part -->
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!--site navigators -->
          <b-navbar-nav align="center">
            <b-nav-item :to="{ path: '/search'}"><b-icon icon="search"></b-icon> SEARCH</b-nav-item>
          </b-navbar-nav>
          <!-- account links -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item @click="login" v-if="!isAuthenticated"><b-icon icon="box-arrow-in-right"></b-icon> Log-in</b-nav-item>
            <b-nav-item @click="signup" v-if="!isAuthenticated"><b-icon icon="pencil-square"></b-icon> Sign-up</b-nav-item>
            <b-nav-item :to="{ path: '/blog'}"><b-icon icon="newspaper"></b-icon> New Blog</b-nav-item>
            <b-nav-item-dropdown center v-if="isAuthenticated">
              <template #button-content>
                <b-avatar variant="primary" size="sm" :src="user.thumbnail"></b-avatar>
                <strong>{{ isAuthenticated ? user.firstName + ' ' + user.lastName : 'User' }}</strong>
              </template>
              <b-dropdown-item @click="openProfile"><b-icon icon="person-fill"></b-icon> Profile</b-dropdown-item>
              <b-dropdown-item @click="logout"><b-icon icon="power"></b-icon> Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NavBar',
  computed: {
    ...mapGetters({
      user: 'USER'
    }),
    isAuthenticated () {
      return this.user.id
    }
  },
  methods: {
    login () {
      this.$store.dispatch('requestLogin')
      this.$router.push({ name: 'UserFormPage' })
    },
    signup () {
      this.$store.dispatch('requestSignup')
      this.$router.push({ name: 'UserFormPage' })
    },
    openProfile () {
      if (this.user.role === 'blogger') {
        this.$router.push('/blogger')
      } else if (this.user.role === 'editor') {
        this.$router.push('/editor')
      } else if (this.user.role === 'admin') {
        this.$router.push('/admin')
      } else {
        this.$store.dispatch('requestLogin')
        this.$router.push({ name: 'UserFormPage' })
      }
    },
    logout () {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-item {
  transition: ease-in .15s;
}
.nav-item:hover {
  background-color: beige;
}
.nav-item:active {
  border-bottom: 5px solid black;
  background-color: beige;
  color: brown;
}
.nav-item-dropdown .dropdown-item:hover {
  background-color: gainsboro;
}

.nav-item-dropdown .dropdown-item:active {
  background-color: gainsboro;
  color: black;
}
</style>
